<template>
  <!--begin::Product Internal Listing-->
  <v-container fluid>
    <ListingTable
      :column-count="columnCount"
      :data-loading="dataLoading"
      :row-data="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Delivery #</th>
            <th class="simple-table-th">Title</th>
            <!--   <th class="simple-table-th">Customer</th> -->
            <th class="simple-table-th">Delivery Date</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Assign To</th>
            <th class="simple-table-th">PDF</th>
            <!-- <th class="simple-table-th">Created Time</th> -->
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length">
            <template v-for="(row, index) in dataRows">
              <v-hover :key="index" v-slot="{ hover }">
                <tr
                  class="alternate-listing-row"
                  :key="index"
                  v-on:click="detailDelivery(row)"
                >
                  <td>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <Barcode
                        small
                        :barcode="row?.barcode"
                        :id="row?.id"
                      ></Barcode>
                    </p>
                    <div class="text-capitalize">
                      <v-chip
                        :class="row?.type == 'transfer' ? '' : 'text-white'"
                        label
                        :color="getColor(row?.type)"
                        small
                      >
                        <v-icon small> {{ getIcon(row?.type) }}</v-icon>
                        {{ row?.type }}
                      </v-chip>
                    </div>
                  </td>
                  <td>
                    <div class="m-0 custom-nowrap-ellipsis d-flex">
                      <template v-if="row?.title">
                        <div class="my-auto ml-1">
                          <read-more
                            class="custom-read-more font-size-16 pb-1"
                            more-str="read more"
                            :text="row?.title || '<em>No Title</em>'"
                            link="#"
                            less-str="read less"
                            :max-chars="35"
                          >
                          </read-more>
                        </div>
                      </template>
                      <em class="text-muted" v-else> no title </em>
                    </div>
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <p
                        class="m-0 custom-nowrap-ellipsis-two-line text-capitalize"
                      >
                        <b>Date: </b> {{ formatDate(row.started_at) }}
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis-two-line text-capitalize"
                      >
                        <b>Time: </b>{{ formatDateTimeStart(row.started_at) }} -
                        {{ formatetimedata(row.finished_at) }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <div class="mb-1 m-2 line-height-initial">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              class="my-auto custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
                              :color="row.qt_status_color"
                              :text-color="row.qt_status_textcolor"
                              label
                              small="small"
                            >
                              <template
                                v-if="
                                  row?.status == 7 && row?.type == 'incoming'
                                "
                              >
                                Returned
                              </template>
                              <template
                                v-else-if="
                                  row?.status == 7 && row?.type == 'transfer'
                                "
                              >
                                Transferred
                              </template>
                              <template v-else>
                                {{ row.qt_status_text }}
                              </template>
                            </v-chip>
                          </template>
                          <span>Status</span>
                        </v-tooltip>
                      </div>
                      <CustomStatus
                        v-if="false"
                        small
                        :status="row?.status"
                        endpoint="delivery/status"
                      ></CustomStatus>
                    </div>
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <p
                        class="m-0 max-content-width text-truncate font-weight-700 text-capitalize"
                        :class="{
                          'text--secondary': lodash.isEmpty(
                            row.delivery_engineers
                          ),
                        }"
                      >
                        {{ engineerDisplayText(row.delivery_engineers) }}
                      </p>
                    </div>
                  </td>
                  <td class="simple-table-td">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!row.pdf_url"
                          color="red"
                          v-on:click.stop.prevent="downloadFile(row.pdf_url)"
                          >mdi-file-pdf</v-icon
                        >
                      </template>
                      <span>Download PDF</span>
                    </v-tooltip>
                  </td>
                  <td class="simple-table-td" v-if="false">
                    <TableActivity :data="row">
                      <template v-slot:date_time>
                        {{ formatDateTime(row?.added_at) }}
                      </template>
                      <template v-slot:format_date_time>
                        {{ formatDate(row?.added_at) }}
                        {{ formatTime(row?.added_at) }}
                      </template>
                    </TableActivity>
                  </td>
                </tr>
              </v-hover>
            </template>
          </template>
          <tr v-else>
            <td :colspan="columnCount">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no delivery at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </v-container>
  <!--end::Product Internal Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import moment from "moment";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";

export default {
  mixins: [CommonMixin, FileManagerMixin, ValidationMixin],
  name: "equipment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      warrantyDurationTypes: [
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      manage_brand: false,
      manage_location: false,
      form_loading: false,
      image_loading: false,
      filter: {
        status: "all",
        search: null,
      },
      timeoutLimit: 500,
    };
  },
  props: {
    type: {
      type: String,
      default: "customer",
    },
    equipmentId: {
      type: Number,
      default: 0,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    saleId: {
      type: Number,
      default: 0,
    },
    rentalId: {
      type: Number,
      default: 0,
    },
  },
  watch: {},
  components: {
    CustomStatus,
    TableActivity,
    /*   Dialog, */

    ListingFooter,
    ListingTable,
    Barcode,
  },
  methods: {
    detailDelivery(data) {
      let routeName = null;
      if (data?.type == "incoming") {
        routeName = "incoming-delivery.detail";
      } else if (data?.type == "outgoing") {
        routeName = "outgoing-delivery.detail";
      } else if (data?.type == "transfer") {
        routeName = "transfer-delivery.detail";
      }
      this.$router.push(
        this.getDefaultRoute(routeName, {
          params: {
            id: data?.id,
          },
        })
      );
    },
    getColor(type) {
      if (type == "incoming") {
        return "incoming-color white--text";
      } else if (type == "outgoing") {
        return "outgoing-color white--text";
      } else if (type == "transfer") {
        return "transfer-color";
      }
    },
    getIcon(type) {
      if (type == "incoming") {
        return "mdi-transfer-down";
      } else if (type == "outgoing") {
        return "mdi-transfer-up";
      } else if (type == "transfer") {
        return "mdi-transfer";
      }
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      //console.log(row,"row");
      return moment(row).format("hh:mm A");
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.get_all_deliveries();
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned.";
      } else {
        return display_name.join(", ");
      }
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    get_all_deliveries() {
      const _this = this;

      let filter = {
        status: "all",
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        customer: this.customerId,
        rental: this.rentalId,
        sale: this.saleId,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "internal/delivery",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    const _this = this;

    //_this.get_options();
    _this.get_all_deliveries();
    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.get_all_deliveries();
    });

    /*   _this.$nextTick(() => {
      _this.equipment.customer = _this.equipmentId;
    }); */
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    columnCount() {
      let result = 9;
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
  },
};
</script>
