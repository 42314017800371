<template>
  <div>
    <v-layout class="p-4 border-bottom-light-grey min-height-57px">
      <v-flex md5 class="font-level-3-bold my-auto">
        <span class="detail-svg-icon mr-2">
          <!--begin::Svg Icon-->
          <inline-svg
            :src="$assetURL('media/custom-svg/reservation-color.svg')"
          />
          <!--end::Svg Icon-->
        </span>
        <!-- {{ getTitle() }} -->
      </v-flex>
    </v-layout>
    <template v-if="contentLoading">
      <!-- <Loading /> -->
    </template>
    <div v-else class="overflow-y">
      <table width="100%" class="detail-table table-head-sticky">
        <thead>
          <tr>
            <!-- <th width="30" class="p-2 blue lighten-4 text-center"></th> -->
            <th width="60" class="p-2 blue lighten-4 text-center">#</th>
            <th width="60" class="p-2 blue lighten-4">Image</th>
            <th width="250" class="p-2 blue lighten-4">Item</th>
            <th width="120" class="p-2 blue lighten-4">UOM</th>
            <th width="120" class="p-2 blue lighten-4 text-center">Qty</th>
            <th width="150" class="p-2 blue lighten-4 text-center">Rate</th>
            <th width="160" class="p-2 blue lighten-4 text-right pr-5">
              Amount
            </th>
          </tr>
        </thead>
        <!-- <tbody v-if="line_items.length"> -->
        <template v-if="line_items.length">
          <tr>
            <!-- <td class="p-2 border-top-light-grey"></td> -->
            <td class="p-2 border-top-light-grey"></td>
            <td colspan="2" class="p-2 border-top-light-grey">
              <!-- <ShowValue :object="line_items_detail" object-key="free_text" label="free text"></ShowValue> -->
            </td>
            <td class="p-2 border-top-light-grey" valign="top">
              <!-- <ShowValue v-if="false" :object="line_items_detail" object-key="uom" label="uom"></ShowValue> -->
            </td>
            <td colspan="3" class="p-2 border-top-light-grey"></td>
          </tr>
          <!-- <Draggable
						tag="tbody"
						v-model="line_items"
						class="draggable-group"
						handle=".draggable-drag-icon"
						
					> -->
          <!--v-on:change="updateLineItem()"-->

          <tr
            v-for="(row, index) in line_items"
            :key="index"
            @click="goToPage(row.type, row.product_id)"
          >
            <!-- <td class="p-2 border-top-light-grey text-center">
								<v-icon color="blue darken-4 " class="cursor-move draggable-drag-icon">mdi-drag</v-icon>
							</td> -->
            <td class="p-2 border-top-light-grey text-center">
              {{
                row.type != "header" && row.type != "other" && row.counting_item
                  ? row.counting_item + "."
                  : ""
              }}
            </td>
            <td class="p-2 border-top-light-grey text-center">
              <v-avatar
                size="40"
                v-if="row.type != 'header' && row.type != 'other'"
              >
                <img
                  v-if="row?.image?.url"
                  :src="row?.image?.url"
                  :alt="row?.image?.name"
                />
                <img v-else :src="$defaultImage" />
              </v-avatar>
              <v-chip color="blue" label class="ml-2 white--text" small v-else>
                <span class="font-size-16 font-weight-500">
                  {{ row.type == "header" ? "Header" : "Other" }}
                </span>
              </v-chip>
            </td>
            <!--:colspan="row.type=='header' || row.type=='other' ? 4:1"-->
            <td class="p-2 border-top-light-grey">
              <template v-if="row.product">
                <span class="font-weight-500">{{ row.product }} </span>
                <v-chip
                  color="blue"
                  label
                  class="ml-2 white--text"
                  small
                  v-if="row.type != 'header' && row.type != 'other'"
                >
                  <span class="font-size-16 font-weight-500">
                    <template v-if="row.type == 'equipment'">
                      {{ row.original_equipment?.serial_no }}
                    </template>
                    <template v-if="row.type == 'product'">
                      {{ row.original_product?.serial_number }}
                    </template>
                  </span>
                </v-chip>
              </template>
              <em v-else class="text-muted"> no item name </em>
              <div style="font-size: 12px" class="custom-nowrap-ellipsis-count">
                {{ row.description }}
              </div>
              <!-- <ShowValue :object="row" object-key="product_name" label="product"></ShowValue> -->
            </td>

            <td class="p-2 border-top-light-grey">
              <template v-if="row.uom">
                {{ row.type != "header" && row.type != "other" ? row.uom : "" }}
              </template>
              <em v-else class="text-muted"> no uom </em>
              <!-- <ShowValue :object="row" object-key="uom" label="uom"></ShowValue> -->
            </td>
            <td class="p-2 border-top-light-grey text-center">
              {{
                row.type != "header" && row.type != "other"
                  ? row.quantity
                    ? row.quantity
                    : 0
                  : ""
              }}
            </td>
            <td class="p-2 border-top-light-grey text-center">
              <template
                v-if="row.is_price_changed && line_items_detail.show_price"
              >
                <v-icon color="red" size="12">mdi-circle</v-icon>
                ({{ row.sprice ? `$${row.sprice}` : "$0" }})
              </template>
              <template v-if="row.rate">
                {{
                  row.type != "header" && row.type != "other"
                    ? formatMoney(row.rate ? row.rate : 0)
                    : ""
                }}
              </template>
            </td>
            <td class="p-2 border-top-light-grey text-right pr-5">
              <template v-if="row.total">
                {{
                  row.type != "header" && row.type != "other"
                    ? formatMoney(row.total ? row.total : 0)
                    : ""
                }}
              </template>
            </td>
          </tr>
          <!-- </Draggable> -->
          <tfoot style="font-size: 14px">
            <tr style="font-size: 1.2rem">
              <td colspan="6" class="p-2 text-right"><b>Sub Total</b></td>
              <td class="p-2 text-right pr-5">
                <b>{{ formatMoney(line_items_detail.sub_total) }}</b>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="p-2 text-right">
                <b
                  >Discount ({{
                    line_items_detail.discount_value_type == 1 ? "$" : ""
                  }}{{ line_items_detail.discount_value
                  }}{{
                    line_items_detail.discount_value_type == 2 ? "%" : ""
                  }})</b
                >
              </td>
              <td class="p-2 text-right pr-5">
                <b> - {{ formatMoney(line_items_detail.discount_amount) }}</b>
              </td>
            </tr>

            <tr>
              <!-- <td colspan="6" class="p-2 text-right">
								<b>{{ line_items_detail.ctx_discount_label }}</b
								>
							</td> -->
              <td class="p-2 text-right" colspan="6">
                <b
                  >{{ line_items_detail.ctx_discount_label }} ({{
                    line_items_detail.ctx_discount_type == 1 ? "$" : ""
                  }}{{ line_items_detail.ctx_discount_value
                  }}{{
                    line_items_detail.ctx_discount_type == 2 ? "%" : ""
                  }})</b
                >
              </td>
              <td class="p-2 text-right pr-5">
                <b> - {{ formatMoney(line_items_detail.ctx_discount) }}</b>
              </td>
            </tr>

            <tr v-if="line_items_detail.tax_applied">
              <td colspan="6" class="p-2 text-right">
                <b>Tax {{ line_items_detail.tax_value }}%</b>
              </td>
              <td class="p-2 text-right pr-5">
                <b>{{ formatMoney(line_items_detail.tax_amount) }}</b>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="p-2 text-right"><b>Adjustment</b></td>
              <td class="p-2 text-right pr-5">
                <b>{{ formatMoney(line_items_detail.adjustment) }}</b>
              </td>
            </tr>
            <tr style="font-size: 1.5rem">
              <td colspan="6" class="p-2 text-right green--text">
                <b>Grand Total</b>
              </td>
              <td class="p-2 text-right green--text pr-5">
                <b>{{ formatMoney(line_items_detail.total) }}</b>
              </td>
            </tr>
          </tfoot>
        </template>
        <!-- </tbody> -->
        <tfoot v-else>
          <tr>
            <td colspan="6">
              <p class="m-0 row-not-found text-center py-3">
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There
                <span class="text-lowercase"
                  >are no {{ getTitle() }} at the moment.</span
                >
              </p>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
//import TextInput from "@/view/components/TextInput";
//import InputEdit from "@/view/components/InputEdit";
//import { GetLineItem } from "@/core/lib/quotation.lib";
//import Loading from "@/view/components/Loading";
//import ShowValue from "@/view/components/ShowValue";
//import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
//import Draggable from "vuedraggable";
import { GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  props: {
    type: {
      type: String,
      default: null,
    },
    typeText: {
      type: String,
      default: null,
    },
    itemType: {
      type: String,
      default: null,
    },
    uuid: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: "all",
    },
  },
  data() {
    return {
      counter: 0,
      customCounting: 0,
      contentLoading: false,
      line_items: [],
      line_items_detail: {
        total: 0,
        sub_total: 0,
        tax_type: 1,
        discount_type: 2,
        discount_value_type: 2,
        total_items: null,
        discount_value: 0,
        discount_amount: 0,
        tax_value: 7,
        taxable_amount: 0,
        tax_amount: 0,
        adjustment: 0,
        tax_applied: 0,
        gst: 0,
      },
      pageLoading: true,
    };
  },
  methods: {
    // customCountingCount(){
    // 	var old = this.customCounting;
    // 	 this.customCounting = old+1;
    // 	 return this.customCounting;
    // },
    // getCounter()
    // {
    // 	console.log(this.counter);
    // 	this.counter = cloneDeep(this.counter+1;
    // 	return this.counter;
    // },
    goToPage(type, productId = 0) {
      console.log(productId);
      if (type == "equipment") {
        this.$router.push(
          this.getDefaultRoute("equipment.detail", {
            params: { id: productId },
          })
        );
        //this.$router.push({ path: "admin.equipment.detail/"+productId });
      }
      if (type == "product") {
        this.$router.push(
          this.getDefaultRoute("product.detail", {
            params: { id: productId },
          })
        );
        //this.$router.push({ path: "admin.product.detail/"+productId });
      }
    },
    getTitle() {
      return "Equipments/Spare parts";
    },
    async getQuotationLineItems(id) {
      console.log(id);
      // const param = {
      // 	type_id: id,
      // 	option: "line_items",
      // };
      this.contentLoading = true;
      //const data = await GetLineItem(param);

      this.$store
        .dispatch(GET, {
          url:
            "line-items-sell-rental/?type_id=" + this.id + "&type=" + this.type,
        })
        .then(({ data }) => {
          this.contentLoading = false;
          this.line_items_detail = data.detail;
          this.line_items = data.line_items;
          this.counter = 0;
          // const { signature_link } = data;
          // window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateValue(value) {
      console.log("UpdateValue", value);
    },
    updateLineItem() {
      console.log(this.line_items);
    },
  },
  components: {
    //TextInput,
    //InputEdit,
    //Draggable,
    //Loading,
    //ShowValue,
    //ShowTextAreaValue,
  },
  // watch:{
  // 	line_items(params){
  // 		console.log(params);
  // 	}
  // },
  mounted() {
    if (this.id) {
      console.log(this.id);
      this.counter = 0;
      this.getQuotationLineItems(this.id);
    }
  },
};
</script>
<style>
.custom-nowrap-ellipsis-count {
  display: block !important;
  -webkit-font-smoothing: antialiased;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
</style>
